@font-face {
  src: url('../assets/fonts/gotham/Gotham-Thin.ttf') format('ttf');
  font-family: 'Gotham';
  font-style: normal;
  font-weight: normal;
}

@font-face {
  src: url('../assets/fonts/gotham/Gotham-Medium.ttf') format('ttf');
  font-family: 'Gotham';
  font-style: normal;
  font-weight: bold;
}

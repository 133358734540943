//RADIO

//since changing base-font-size make some wired margin
.custom-control-label {
  line-height: 1.5rem;
}

//FORM

.form-group label:not(.form-check-label) {
  font-weight: 500;
}
.form-control {
  border: 0;
  border-bottom: 1px solid $dark;
  margin-bottom: 10px;
}

//navigation
.navigation .nav-item {
  font-size: 1.25rem;
}

.text-regular-36 {
  font-size: 36px;
}

//MODALS
.modal-footer {
  padding-top: 0;
  border-top: none;
  flex-direction: column;
  align-items: stretch;

  > :not(:last-child) {
    margin-bottom: 0.5rem;
    margin-right: 0;
  }
  > :not(:first-child) {
    margin-left: 0;
  }

  @include media-breakpoint-up(md) {
    align-items: center;
    flex-direction: row;

    > :not(:last-child) {
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
    > :not(:first-child) {
      margin-left: 0.25rem;
    }
  }
}
//TOOLTIPS

.tooltip {
  .tooltip-inner {
    box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.35);
  }
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
//overide variables to create custom template
@import "./bootstrap-theme/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/dropdown";

@import "core";
@import "bootstrap-theme/extend";
@import "bootstrap-theme/modyfications";
@import "fonts";
@import "text";
@import "layout";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  background: $body-bg; // 2
  user-select: text;
  color: $black !important;
  scroll-behavior: smooth;
  .modal-open {
    overflow: visible;
  }
}

main {
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .logo {
    margin-left: auto;
    margin-right: auto;
  }
}

.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

.checkbox > span {
  color: #34495E;
  padding: 0.5rem 0.25rem;
}

.checkbox > input {
  height: 25px;
  width: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #34495E;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: #41B883;
  cursor: pointer;
}

.checkbox > input:checked {
  border: 1px solid #41B883;
  background-color: #34495E;
}

.checkbox > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: #41B883;
  position: absolute;
  left: 0.7rem;
  top: 0.2rem;
}

.checkbox > input:active {
  border: 2px solid #34495E;
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}